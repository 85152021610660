<template>
  <div class="flex flex-col justify-start items-start min-height py-8">
    <div
      v-if="reservation"
      class="border border-gray-200 sm:rounded-lg w-full overflow-hidden mb-8"
    >
      <table class="rounded-lg min-w-full divide-y divide-gray-200">
        <thead class="bg-gray-50 overflow-hidden">
          <tr>
            <th
              scope="col"
              class="overflow-hidden rounded-lg px-6 py-3 text-left text-sm font-medium text-gray-500 capitalize tracking-wider"
            >
              Código
            </th>
            <th
              scope="col"
              class="overflow-hidden rounded-lg px-6 py-3 text-left text-sm font-medium text-gray-500 capitalize tracking-wider"
            >
              Nombres
            </th>
            <th
              scope="col"
              class="overflow-hidden rounded-lg px-6 py-3 text-left text-sm font-medium text-gray-500 capitalize tracking-wider"
            >
              Fecha Reserva
            </th>
            <th
              scope="col"
              class="overflow-hidden rounded-lg px-6 py-3 text-left text-sm font-medium text-gray-500 capitalize tracking-wider"
            >
              Estado Reserva
            </th>
          </tr>
        </thead>
        <tbody class="bg-white divide-y divide-gray-200">
          <tr>
            <td class="px-6 py-4 whitespace-nowrap">
              <div class="text-sm w-full text-left font-medium text-gray-900">
                <div class="flex flex-col justify-center items-start">
                  <span class="inline-block uppercase"
                    ><span class="whitespace-normal break-word">{{
                      reservation.id
                    }}</span></span
                  >
                </div>
              </div>
              <!---->
            </td>
            <td class="px-6 py-4 whitespace-nowrap">
              <div class="text-sm w-full text-left font-medium text-gray-900">
                <div class="flex flex-col justify-center items-start">
                  <span class="inline-block capitalize !w-10">
                    <span
                      class="whitespace-normal break-word capitalize font-bold"
                      >Nombre Paciente</span
                    >
                  </span>
                  <span class="inline-block capitalize !w-10 mb-2">
                    <span class="whitespace-normal break-word capitalize"
                      >{{ reservation.purchase.user.names }}
                      {{ reservation.purchase.user.lastnames }}</span
                    >
                  </span>
                  <span class="inline-block capitalize !w-10">
                    <span
                      class="whitespace-normal break-word capitalize font-bold"
                      >Nombre Profesional</span
                    >
                  </span>
                  <span class="inline-block capitalize !w-10">
                    <span class="whitespace-normal break-word capitalize"
                      >{{ reservation.block.user.names }}
                      {{ reservation.block.user.lastnames }}</span
                    > </span
                  ><span
                    class="inline-block w-auto text-green-900 bg-green-100 rounded-md px-2 mt-1 lowercase"
                  >
                    <span
                      v-if="reservation.block.user.doctor"
                      class="whitespace-normal break-word"
                      >{{ reservation.block.user.doctor.specialty }}</span
                    >
                    <span
                      v-if="reservation.block.user.superdoctor"
                      class="whitespace-normal break-word"
                      >{{ reservation.block.user.superdoctor.specialty }}</span
                    >
                    <span
                      v-if="reservation.block.user.psycholigst"
                      class="whitespace-normal break-word"
                      >{{ reservation.block.user.psycholigst.specialty }}</span
                    >
                  </span>
                </div>
              </div>
              <!---->
            </td>
            <td class="px-6 py-4 whitespace-nowrap">
              <div class="text-sm w-full text-left font-medium text-gray-900">
                <div class="flex flex-col justify-center items-start">
                  <span class="inline-block"
                    ><span class="whitespace-normal break-word">{{
                      formatDateNative(reservation.block.start_date)
                    }}</span></span
                  ><span class="inline-block"
                    ><span class="whitespace-normal break-word">{{
                      formatDateNativeHour(reservation.block.start_date)
                    }}</span></span
                  >
                </div>
              </div>
              <!---->
            </td>
            <td class="px-6 py-4 whitespace-nowrap">
              <div class="text-sm w-full text-left font-medium text-gray-900">
                <div class="flex flex-col justify-center items-start">
                  <span class="inline-block"
                    ><span class="whitespace-normal break-word">{{
                      !reservation.bond
                        ? 'Pendiente pago'
                        : reservation.bond.accepted
                        ? 'Confirmada'
                        : reservation.bond.rejected
                        ? 'Rechazada'
                        : 'Pendiente Aprobación'
                    }}</span></span
                  >
                </div>
              </div>
              <!---->
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <span
      v-if="reservation.bond"
      class="text-xl text-gray-900 font-semibold mb-4"
      >Estado del Bono</span
    >
    <div
      v-if="reservation.bond"
      class="w-full flex-col justify-center items-center mb-2"
    >
      <select
        v-model="status"
        class="col-span-2 md:col-span-1 mt-2 md:mt-0 w-full disabled:text-gray-400 border border-gray-200 bg-white rounded-md focus:outline-none focus:ring-green-500 focus:border-green-500 sm:text-sm"
      >
        <option
          v-for="(statusElement, statusIndex) in statuses"
          :key="statusIndex"
          :value="statusElement"
          >{{ statusElement }}</option
        >
      </select>
      <textarea
        name="note"
        rows="5"
        v-model="comments"
        placeholder="Sin Comentarios"
        class="shadow-sm mt-4 h-50 mb-2 resize-none focus:ring-green-500 focus:border-green-500 mt-1 block w-full sm:text-sm border border-gray-300 rounded-md"
      ></textarea>
    </div>
    <div v-if="reservation.bond" class="w-full flex justify-end mb-4">
      <button
        type="button"
        @click="save"
        class="bg-green-600 mb-2 my-2 hover:bg-green-700 text-gray-50 font-bold px-3 py-2 rounded-md text-sm font-medium"
      >
        Guardar
      </button>
    </div>
    <span class="text-xl text-gray-900 font-semibold mt-4 mb-4"
      >Bono de la reserva</span
    >
    <div class="grid grid-cols-1 w-full gap-2">
      <div
        class="col px-4 py-2 rounded-md border-dashed border-2 border-gray-200 flex flex-col"
      >
        <span class="text-lg text-gray-700 font-semibold mb-4 w-full text-left"
          >Archivo</span
        >
        <div
          :class="
            reservation && reservation.bond && reservation.bond.pdf
              ? 'pt-2 pb-4 px-5 '
              : 'pb-10 pt-3 px-5 '
          "
          class="flex flex-col justify-center items-center"
        >
          <svg
            v-if="!(reservation && reservation.bond && reservation.bond.pdf)"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="w-8 h-8 text-gray-500"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z"
            />
          </svg>

          <span
            v-if="reservation && reservation.bond && reservation.bond.pdf"
            class="text-sm text-gray-400 font-semibold"
            >{{ reservation.bond.name }}</span
          >
          <span v-else class="text-sm text-gray-400 font-semibold"
            >Sin Archivo</span
          >
        </div>
        <pdf
          class="w-full"
          v-if="reservation && reservation.bond && reservation.bond.pdf"
          :src="reservation.bond.pdf"
          :page="1"
        />
        <div class="w-full flex justify-end">
          <button
            type="button"
            v-if="reservation && reservation.bond && reservation.bond.pdf"
            @click="deleteBondDatabase"
            class="bg-red-200 mb-2 my-4 hover:bg-red-300 text-red-900 font-bold px-3 py-2 rounded-md text-sm font-medium"
          >
            Borrar archivo
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import pdf from 'vue-pdf';
import ReservationService from '@/services/reservationService';
import BondService from '@/services/bondService';
import moment from 'moment';

export default {
  name: 'Bond',
  components: {
    pdf,
  },
  data() {
    return {
      reservation: null,
      status: 'No Cargado',
      comments: '',
      statuses: ['No Cargado', 'Pendiente', 'Aceptado', 'Rechazado'],
      backend: process.env.VUE_APP_BACKEND_URL.replace('/api', ''),
    };
  },
  created() {
    this.updateData();
  },
  methods: {
    formatDateNative(date) {
      moment.locale('es');
      let date_temp = moment(date, 'YYYY-MM-DD HH:mm');
      date_temp.locale(false);
      return date_temp.format('L');
    },
    formatDateNativeHour(date) {
      moment.locale('es');
      let date_temp = moment(date, 'YYYY-MM-DD HH:mm');
      date_temp.locale(false);
      return date_temp.format('HH:mm A');
    },
    updateData() {
      ReservationService.getReservation(this.$route.params.reservation_id)
        .then(response => {
          if (response.data.reservation && response.data.reservation.bond) {
            BondService.getBondFile(this.$route.params.reservation_id)
              .then(response_bond => {
                let url = Buffer.from(response_bond.data).toString('base64');
                let reservation_temp = response.data.reservation;
                reservation_temp.bond.pdf = `data:application/pdf;base64,${url}`;
                this.reservation = reservation_temp;
                this.comments = this.reservation.bond.comments
                  ? this.reservation.bond.comments
                  : '';
                this.status = this.reservation.bond.accepted
                  ? 'Aceptado'
                  : this.reservation.bond.rejected
                  ? 'Rechazado'
                  : 'Pendiente';
              })
              .catch(() => {
                this.reservation = response.data.reservation;
                this.comments = this.reservation.bond.comments
                  ? this.reservation.bond.comments
                  : '';
                this.status = this.reservation.bond.accepted
                  ? 'Aceptado'
                  : this.reservation.bond.rejected
                  ? 'Rechazado'
                  : 'No Cargado';
              });
          } else {
            this.reservation = response.data.reservation;
            this.comments = this.reservation.bond.comments
              ? this.reservation.bond.comments
              : '';
            this.status = this.reservation.bond.accepted
              ? 'Aceptado'
              : this.reservation.bond.rejected
              ? 'Rechazado'
              : 'No Cargado';
          }
        })
        .catch(() => {
          //this.$router.replace('/home');
        });
    },
    save() {
      this.$AreYouSure.fire().then(result => {
        if (result.isConfirmed) {
          switch (this.status) {
            case 'No Cargado':
              if (this.reservation.bond && this.reservation.bond.pdf) {
                BondService.deleteBond(this.reservation.id)
                  .then(() => {
                    this.$Success.fire({
                      title: 'Bono eliminado',
                    });
                    this.updateData();
                  })
                  .catch(() => {
                    this.$Error.fire({
                      title: 'Error al eliminar el bono',
                    });
                  });
              } else {
                this.$Success.fire({
                  title: 'Estado Cambiado',
                });
              }
              break;
            default:
              BondService.updateSatatusBond(this.reservation.id, {
                accepted: this.status === 'Aceptado',
                rejected: this.status === 'Rechazado',
                pending: this.status === 'Pendiente',
                comments: this.comments ? this.comments : null,
              })
                .then(() => {
                  this.$Success.fire({
                    title: 'Estado Cambiado',
                  });
                  this.updateData();
                })
                .catch(() => {
                  this.$Error.fire({
                    title: 'Error al cambiar el estado del bono',
                  });
                });
              break;
          }
        }
      });
    },
    deleteBondDatabase() {
      this.$AreYouSure.fire().then(result => {
        if (result.isConfirmed) {
          BondService.deleteBond(this.reservation.id)
            .then(() => {
              this.$Success.fire({
                title: 'Bono eliminado',
              });
              this.updateData();
            })
            .catch(() => {
              this.$Error.fire({
                title: 'Error al eliminar el bono',
              });
            });
        }
      });
    },
  },
  watch: {
    '$route.params.reservation_id'() {
      this.updateData();
    },
  },
  computed: {
    ...mapGetters({
      user: ['authentication/user'],
    }),
  },
};
</script>

<style scoped>
.min-height {
  min-height: 80vh;
}
</style>
